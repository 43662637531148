import { useState, useEffect, useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { api } from '../services/api';
import { Entry } from '../types/api';
import FilterPanel, { FilterValues } from './common/FilterPanel';

const MAX_POLLING_ATTEMPTS = 20; // For example, stop after 1 minute (20 * 3 seconds)

function Entries() {
  const [entries, setEntries] = useState<Entry[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pollingCount, setPollingCount] = useState(0);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);
  const [filters, setFilters] = useState<FilterValues>({
    'filter[owner]': '',
    'filter[entryid]': '',
  });

  // Get page parameters from URL
  const pageSize = parseInt(searchParams.get('page[size]') || '10');
  const pageNumber = parseInt(searchParams.get('page[number]') || '1');

  const filterFields = [
    {
      name: 'filter[owner]' as const,
      label: 'Owner',
      type: 'text' as const,
    },
    {
      name: 'filter[entryid]' as const,
      label: 'Entry ID',
      type: 'text' as const,
    },
  ];

  const fetchEntries = useCallback(async () => {
    try {
      const params = new URLSearchParams(searchParams);
      const response = await api.getEntries(params);
      setEntries(response.data);
      setHasNextPage(!!response.links.next);
      
      // If entries are found, clear the polling interval
      if (response.data.length > 0) {
        if (pollingInterval) {
          clearInterval(pollingInterval);
          setPollingInterval(null);
        }
        setLoading(false);
      } else {
        // If no entries found and not already polling, start polling
        if (!pollingInterval) {
          const interval = setInterval(() => {
            setPollingCount(count => count + 1);
          }, 3000);
          setPollingInterval(interval);
        }
      }
    } catch (error) {
      console.error('Error fetching entries:', error);
      setLoading(false);
      if (pollingInterval) {
        clearInterval(pollingInterval);
        setPollingInterval(null);
      }
    }
  }, [searchParams, pollingInterval]);

  // Effect for initial fetch and cleanup
  useEffect(() => {
    fetchEntries();
    
    // Cleanup function to clear interval when component unmounts
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [fetchEntries]);

  // Effect for polling
  useEffect(() => {
    if (pollingCount > 0) {
      fetchEntries();
    }
  }, [pollingCount, fetchEntries]);

  const handleFilterChange = (newFilters: FilterValues) => {
    setFilters(newFilters);
    
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page[number]', '1');
    
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value.toString());
      } else {
        newParams.delete(key);
      }
    });

    setSearchParams(newParams);
  };

  const handleFilterSubmit = () => {
    // Filter submit is handled by handleFilterChange
  };

  const handlePageChange = (newPage: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page[number]', String(newPage));
    newParams.set('page[size]', String(pageSize));
    setSearchParams(newParams);
  };

  const LoadingSpinner = () => (
    <div className="flex flex-col items-center justify-center space-y-4">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      <span className="text-gray-700 text-lg">Please wait, entry is being processed...</span>
      {pollingCount > 0 && (
        <span className="text-gray-500 text-sm">
          Checking again... (Attempt {pollingCount})
        </span>
      )}
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Entries</h1>

      <FilterPanel
        fields={filterFields}
        values={filters}
        onChange={handleFilterChange}
        onSubmit={handleFilterSubmit}
      />

      {loading || entries.length === 0 ? (
        <div className="mt-8 flex justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="grid gap-4">
            {entries.map((entry) => (
              <div key={entry.uuid} className="bg-white rounded-lg shadow-sm p-6">
                <Link to={`/entries/${entry.uuid}`}>
                  <h2 className="font-bold">Entry {entry.uuid.substring(0, 20)}...</h2>
                  <p className="text-gray-600">Kind: {entry.entry_kind}</p>
                  <p className="text-gray-600">Owner: {entry.owner}</p>
                  <p className="text-gray-600">
                    Created: {new Date(entry.metadata.created_at).toLocaleString()}
                  </p>
                </Link>
              </div>
            ))}
          </div>

          <div className="mt-6 flex justify-center space-x-4">
            <button
              onClick={() => handlePageChange(pageNumber - 1)}
              disabled={pageNumber === 1}
              className="px-4 py-2 bg-white border border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(pageNumber + 1)}
              disabled={!hasNextPage}
              className="px-4 py-2 bg-white border border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Entries;
