import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Blocks from './components/Blocks';
import BlockDetails from './components/BlockDetails';
import TransactionDetails from './components/TransactionDetails';
import Entries from './components/Entries';
import EntryDetails from './components/EntryDetails';
import Navigation from './components/Navigation';

function App() {
  const [basePath, setBasePath] = useState('');

  useEffect(() => {
    // Check if we're under /explorer/v1
    const path = window.location.pathname;
    if (path.startsWith('/explorer/v1')) {
      setBasePath('/explorer/v1');
    }
  }, []);

  return (
    <Router basename={basePath}>
      <div className="app">
        <Navigation />
        <main className="container mx-auto p-4">
          <Routes>
            <Route path="/" element={<Blocks />} />
            <Route path="/blocks/:blockId" element={<BlockDetails />} />
            <Route path="/transactions/:transactionId" element={<TransactionDetails />} />
            <Route path="/entries" element={<Entries />} />
            <Route path="/entries/:entryId" element={<EntryDetails />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
