import { Block, BlockState } from '../types/api';

export const getBlockState = (block: Block): BlockState => {
  const createdDate = new Date(block.created_at);
  const now = new Date();
  const hourInMilliseconds = 60 * 60 * 1000;
  
  if ((now.getTime() - createdDate.getTime()) >= hourInMilliseconds) {
    return 'final';
  }
  
  return block.node_signature ? 'verified' : 'created';
};

export const getStateColor = (state: BlockState): string => {
  switch (state) {
    case 'created':
      return 'text-yellow-600 bg-yellow-100';
    case 'verified':
      return 'text-blue-600 bg-blue-100';
    case 'final':
      return 'text-green-600 bg-green-100';
    default:
      return 'text-gray-600 bg-gray-100';
  }
};
