import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Transaction } from '../types/api';
import { api } from '../services/api';
import DetailTable from './common/DetailTable';
import OperationDetails from './common/OperationDetails';

function TransactionDetails() {
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        if (!transactionId) return;
        const response = await api.getTransaction(transactionId);
        setTransaction(response.data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [transactionId]);

  if (loading) return <div>Loading...</div>;
  if (!transaction) return <div>Transaction not found</div>;

  const transactionData = {
    'Transaction Hash': transaction.hash,
    'UUID': transaction.uuid,
    'Owner': transaction.owner,
    'Date': new Date(transaction.date).toLocaleString(),
    'Signature': transaction.signature,
  };

  return (
    <div className="container mx-auto p-4 space-y-6">
      <h1 className="text-2xl font-bold mb-4">Transaction Details</h1>
      <DetailTable data={transactionData} />
      
      {transaction.operations.map((op, index) => (
  <OperationDetails 
    key={`${index}-${op.type}`}
    operation={op}
    index={index}
  />
))}
    </div>
  );
}

export default TransactionDetails;
