import DetailTable from './DetailTable';
import JsonTable from './JsonTable';

interface OperationDetailsProps {
  operation: any; // We'll properly type this later
  index: number;
}

function OperationDetails({ operation: op, index }: OperationDetailsProps) {
  const getOperationIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'create':
        return (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        );
      case 'update':
        return (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
        );
      default:
        return (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </svg>
        );
    }
  };

  return (
    <div className="bg-white rounded-lg p-4 shadow-sm">
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center space-x-2">
          <span className="text-gray-500">
            {getOperationIcon(op.type)}
          </span>
          <h3 className="font-medium text-gray-900">
            Operation #{index + 1}
          </h3>
        </div>
        <div className="flex items-center space-x-2">
          {op.entry_type && (
            <span className="text-sm text-gray-600">
              Type: {op.entry_type}
            </span>
          )}
          <span className="px-2 py-1 bg-gray-100 rounded text-sm font-medium text-gray-700">
            {op.type}
          </span>
        </div>
      </div>

      {op.type === 'create' && op.entry && (
        <div className="mt-4 space-y-4">
          <div className="bg-gray-50 rounded-lg p-4">
            <h4 className="text-sm font-medium text-gray-700 mb-3">Entry Details</h4>
            <div className="space-y-4">
              <DetailTable
                data={{
                  'ID': op.entry.id,
                  'UUID': op.entry.uuid,
                  'Type': op.entry.type,
                  'Entry Kind': op.entry.entry_kind,
                  'Owner': op.entry.owner,
                  'Entry Data Hash': op.entry.entry_data_hash,
                  'Signature': op.entry.signature,
                  'Created At': new Date(op.entry.metadata.created_at).toLocaleString(),
                  'Updated At': new Date(op.entry.metadata.updated_at).toLocaleString(),
                }}
              />
              
              {op.entry.entry_data && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">Entry Data</h4>
                  <div className="bg-white rounded p-4">
                    <JsonTable data={op.entry.entry_data} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {op.type !== 'create' && op.data && (
        <div className="mt-3">
          <h4 className="text-sm font-medium text-gray-700 mb-2">Operation Data</h4>
          <div className="bg-gray-50 rounded-lg p-4">
            <JsonTable data={op.data} />
          </div>
        </div>
      )}
    </div>
  );
}

export default OperationDetails;
