import { useState, useEffect, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Block } from "../types/api";
import { api } from "../services/api";
import { getBlockState, getStateColor } from "../utils/blockUtils";
import FilterPanel from './common/FilterPanel';
import { FilterValues } from './common/FilterPanel';  // Import the type directly

function BlockCard({ block }: { block: Block }) {
  const state = getBlockState(block);
  const stateColorClass = getStateColor(state);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h2 className="text-xl font-semibold text-secondary">
            Block #{block.height}
          </h2>
          <p className="text-gray-500 text-sm mt-1">
            Hash: {block.block_hash.substring(0, 20)}...
          </p>
        </div>
        <span
          className={`px-3 py-1 rounded-full text-sm font-medium ${stateColorClass}`}
        >
          {state.charAt(0).toUpperCase() + state.slice(1)}
        </span>
      </div>

      <div className="mt-4">
        <p className="text-sm text-gray-600">
          Created: {new Date(block.created_at).toLocaleString()}
        </p>
        {block.transactions && (
          <p className="text-sm text-gray-600 mt-2">
            Transactions: {block.transactions.length}
          </p>
        )}
      </div>
    </div>
  );
}

function Blocks() {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [filters, setFilters] = useState<FilterValues>({
    "filter[blockhash]": "",
    "filter[blockindex]": "",
  });

  const pageNumber = parseInt(searchParams.get("page[number]") || "1");

  const filterFields = [
    {
      name: "filter[blockindex]" as const,
      label: "Height",
      type: "text" as const,
    },
    {
      name: "filter[blockhash]" as const,
      label: "Block Hash",
      type: "text" as const,
    },
  ];

  const fetchBlocks = useCallback(async () => {
    try {
      const params = new URLSearchParams(searchParams);
      const response = await api.getBlocks(params);
      setBlocks(response.data);
      setHasNextPage(!!response.links.next);
    } catch (error) {
      console.error("Error fetching blocks:", error);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchBlocks();
  }, [fetchBlocks, searchParams]); // Add searchParams to dependencies

  const handleFilterChange = (newFilters: FilterValues) => {
    // Update local filter state
    setFilters(newFilters);
  };

  const handleFilterSubmit = () => {
    console.log('Filter submit called with filters:', filters); // Add logging
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page[number]', '1');
    
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value.toString());
      } else {
        newParams.delete(key);
      }
    });

    console.log('New params:', newParams.toString()); // Add logging
    setSearchParams(newParams);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Blocks</h1>

      <FilterPanel
        fields={filterFields}
        values={filters}
        onChange={handleFilterChange}
        onSubmit={handleFilterSubmit}
      />

      {blocks.length === 0 ? (
        <div className="text-center py-8 text-gray-500">
          No blocks found
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {blocks.map((block) => (
            <Link key={block.id} to={`/blocks/${block.id}`}>
              <BlockCard block={block} />
            </Link>
          ))}
        </div>
      )}

      <div className="mt-8 flex justify-center space-x-4">
        <button
          onClick={() => {
            const newParams = new URLSearchParams(searchParams);
            newParams.set('page[number]', String(pageNumber - 1));
            setSearchParams(newParams);
          }}
          disabled={pageNumber === 1}
          className="px-4 py-2 border border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() => {
            const newParams = new URLSearchParams(searchParams);
            newParams.set('page[number]', String(pageNumber + 1));
            setSearchParams(newParams);
          }}
          disabled={!hasNextPage}
          className="px-4 py-2 border border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Blocks;
