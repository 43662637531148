import React, { useState } from 'react';

export interface FilterField {
  name: string;
  label: string;
  type: 'text' | 'select' | 'date';
  options?: { label: string; value: string }[];
}

export interface FilterValues {
  [key: string]: string | undefined;
}

export interface FilterPanelProps {
  fields: FilterField[];
  values: FilterValues;
  onChange: (values: FilterValues) => void;
  onSubmit: () => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({ fields, values, onChange, onSubmit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [localValues, setLocalValues] = useState(values);
  const hasActiveFilters = Object.values(values).some(value => value && value.length > 0);

  const handleChange = (name: string, value: string) => {
    setLocalValues({
      ...localValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onChange(localValues);
    onSubmit();
  };

  const handleClearFilters = (e: React.MouseEvent) => {
    e.stopPropagation();
    const clearedValues = Object.keys(values).reduce((acc, key) => ({
      ...acc,
      [key]: '',
    }), {});
    setLocalValues(clearedValues);
    onChange(clearedValues);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm mb-6">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-3 flex items-center justify-between text-left hover:bg-gray-50"
      >
        <div className="flex items-center">
          <svg
            className={`h-5 w-5 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
          <span className="ml-2 font-medium">Filters</span>
          {hasActiveFilters && (
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              Active
            </span>
          )}
        </div>
        {hasActiveFilters && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleClearFilters(e);
            }}
            className="px-2 py-1 text-sm text-gray-600 hover:text-gray-900 cursor-pointer"
          >
            Clear all
          </div>
        )}
      </button>

      {isExpanded && (
        <form onSubmit={handleSubmit} className="p-4 border-t border-gray-100">
          <div className="grid gap-4 md:grid-cols-2">
            {fields.map((field) => (
              <div key={field.name}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {field.label}
                </label>
                {field.type === 'select' ? (
                  <select
                    value={localValues[field.name] || ''}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 bg-white focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">All</option>
                    {field.options?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    value={localValues[field.name] || ''}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    placeholder={`Filter by ${field.label.toLowerCase()}`}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  />
                )}
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Apply Filters
            </button>
          </div>
        </form>
      )}

      {!isExpanded && hasActiveFilters && (
        <div className="px-4 py-2 border-t border-gray-100">
          <div className="flex flex-wrap gap-2">
            {Object.entries(values).map(([key, value]) => {
              if (!value) return null;
              const field = fields.find(f => f.name === key);
              return (
                <span
                  key={key}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                >
                  {field?.label}: {value}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const newValues = { ...values, [key]: '' };
                      setLocalValues(newValues);
                      onChange(newValues);
                    }}
                    className="ml-1 hover:text-gray-900"
                  >
                    ×
                  </button>
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterPanel;
