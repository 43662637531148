import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

interface Column {
  header: string;
  dataKey: string;
}

const flattenObject = (obj: any, prefix = ''): Record<string, string> => {
  const flattened: Record<string, string> = {};

  for (const key in obj) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (value === null) {
      flattened[newKey] = 'null';
    } else if (value === undefined) {
      flattened[newKey] = 'undefined';
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value, newKey));
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === 'object') {
          Object.assign(flattened, flattenObject(item, `${newKey}[${index}]`));
        } else {
          flattened[`${newKey}[${index}]`] = String(item);
        }
      });
    } else {
      flattened[newKey] = String(value);
    }
  }

  return flattened;
};

export const downloadJsonAsPdf = (data: Record<string, any>, filename: string = 'entry-data', metadata?: {
  entryId?: string;
  blockId?: string;
  blockHeight?: number;
}) => {
  const doc = new jsPDF();
  const baseUrl = window.location.origin;
  
  // Add title
  doc.setFontSize(16);
  doc.text('Entry Data', 14, 20);

  // Add links section if metadata is provided
  let startY = 30;
  if (metadata) {
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 255);
    
    if (metadata.entryId) {
      const entryUrl = `${baseUrl}/entries/${metadata.entryId}`;
      doc.textWithLink('View Entry in Browser', 14, startY, { url: entryUrl });
      startY += 7;
    }

    if (metadata.blockId) {
      const blockUrl = `${baseUrl}/blocks/${metadata.blockId}`;
      doc.textWithLink(`View in Block #${metadata.blockHeight}`, 14, startY, { url: blockUrl });
      startY += 10;
    }
    
    doc.setTextColor(0, 0, 0);
  }

  // Flatten the JSON object
  const flattenedData = flattenObject(data);
  
  // Convert to array format for autoTable
  const tableData = Object.entries(flattenedData).map(([key, value]) => ({
    key,
    value
  }));

  // Define columns
  const columns: Column[] = [
    { header: 'Key', dataKey: 'key' },
    { header: 'Value', dataKey: 'value' }
  ];

  // Add table
  (doc as any).autoTable({
    startY: startY + 5,
    head: [columns.map(col => col.header)],
    body: tableData.map(row => [row.key, row.value]),
    theme: 'grid',
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: 'bold',
    },
    styles: {
      fontSize: 10,
      cellPadding: 5,
      overflow: 'linebreak',
      cellWidth: 'wrap',
    },
    columnStyles: {
      0: { cellWidth: 80 },
      1: { cellWidth: 'auto' },
    },
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
  });

  // Save the PDF
  doc.save(`${filename}.pdf`);
};
