import React from 'react';
import CopyButton from './CopyButton';
import { downloadJsonAsPdf } from '../../utils/pdfUtils';

interface JsonTableProps {
  data: Record<string, any>;
  level?: number;
  showDownload?: boolean;
  metadata?: {
    entryId?: string;
    blockId?: string;
    blockHeight?: number;
  };
}

function JsonTable({ data, level = 0, showDownload = false, metadata }: JsonTableProps) {
  const shouldShowCopyButton = (key: string, value: any) => {
    const copyFields = ['id', 'hash', 'uuid', 'signature'];
    return copyFields.some(field => key.toLowerCase().includes(field)) && typeof value === 'string';
  };

  const renderValue = (key: string, value: any): React.ReactNode => {
    if (value === null) return <span className="text-gray-400">null</span>;
    if (value === undefined) return <span className="text-gray-400">undefined</span>;
    if (typeof value === 'boolean') return value ? 'true' : 'false';
    
    if (Array.isArray(value)) {
      return (
        <div className="pl-4 space-y-2 mt-2">
          {value.map((item, index) => (
            <div key={index} className="border-l-2 border-gray-200 pl-2">
              <div className="flex items-center">
                <span className="text-gray-500 mr-2">[{index}]</span>
                {typeof item === 'object' ? (
                  <div className="flex-1">
                    <JsonTable data={item} level={level + 1} />
                  </div>
                ) : (
                  <span className="break-all">{String(item)}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }
    
    if (typeof value === 'object') {
      return (
        <div className="pl-4 border-l-2 border-gray-200 mt-2 w-full">
          <JsonTable data={value} level={level + 1} />
        </div>
      );
    }

    return (
      <div className="flex items-center space-x-2">
        <span className="break-all">{String(value)}</span>
        {shouldShowCopyButton(key, value) && <CopyButton text={value.toString()} />}
      </div>
    );
  };

  return (
    <div className={`w-full ${level > 0 ? 'mt-2' : ''}`}>
      {level === 0 && showDownload && (
        <div className="flex justify-end mb-4">
          <button
            onClick={() => downloadJsonAsPdf(data, 'entry-data', metadata)}
            className="flex items-center px-2 md:px-3 py-1 md:py-2 bg-white border border-gray-300 rounded-md text-xs md:text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            <svg 
              className="w-3 h-3 md:w-4 md:h-4 mr-1 md:mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
              />
            </svg>
            Download PDF
          </button>
        </div>
      )}
      
      <div className="min-w-full overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          {Object.entries(data).map(([key, value], index) => (
            <div
              key={key}
              className={`
                grid grid-cols-1 md:grid-cols-[minmax(150px,auto)_1fr] gap-2 md:gap-4 py-2 px-3 md:px-4
                ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                ${level === 0 ? 'border-b border-gray-200' : ''}
              `}
            >
              <div className="font-medium text-gray-700 break-words text-sm md:text-base">{key}</div>
              <div className="font-mono text-xs md:text-sm break-all">{renderValue(key, value)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default JsonTable;
