import { apiClient } from './apiClient';
import { ApiResponse, Block, Transaction, Entry } from '../types/api';
import { API_CONFIG } from 'config/api';

export const api = {
  async getBlocks(params: URLSearchParams): Promise<ApiResponse<Block[]>> {
    const { data } = await apiClient.get(`${API_CONFIG.ENDPOINTS.BLOCKS}?${params}`);
    return data;
  },

  async getBlock(id: string): Promise<ApiResponse<Block>> {
    const { data } = await apiClient.get(`${API_CONFIG.ENDPOINTS.BLOCKS}/${id}`);
    return data;
  },

  async getTransactions(params: URLSearchParams): Promise<ApiResponse<Transaction[]>> {
    const { data } = await apiClient.get(`${API_CONFIG.ENDPOINTS.TRANSACTIONS}?${params}`);
    return data;
  },

  async getTransaction(id: string): Promise<ApiResponse<Transaction>> {
    const { data } = await apiClient.get(`${API_CONFIG.ENDPOINTS.TRANSACTIONS}/${id}`);
    return data;
  },

  async getEntries(params: URLSearchParams): Promise<ApiResponse<Entry[]>> {
    const { data } = await apiClient.get(`${API_CONFIG.ENDPOINTS.ENTRIES}?${params}`);
    return data;
  },

  async getEntry(id: string): Promise<ApiResponse<Entry>> {
    const { data } = await apiClient.get(`${API_CONFIG.ENDPOINTS.ENTRIES}/${id}`);
    return data;
  }
};
