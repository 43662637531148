import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Entry } from '../types/api';
import { api } from '../services/api';
import JsonTable from './common/JsonTable';

function EntryDetails() {
  const { entryId } = useParams();
  const [entry, setEntry] = useState<Entry | null>(null);
  const [loading, setLoading] = useState(true);
  const [blockId, setBlockId] = useState<string | null>(null);

  useEffect(() => {
    const fetchEntry = async () => {
      try {
        if (!entryId) return;
        const response = await api.getEntry(entryId);
        setEntry(response.data);
        
        // If we have a block hash, fetch the block to get its ID
        if (response.data.metadata.block_hash) {
          const blockResponse = await api.getBlocks(new URLSearchParams({
            'filter[blockhash]': response.data.metadata.block_hash
          }));
          if (blockResponse.data.length > 0) {
            setBlockId(blockResponse.data[0].id.toString());
          }
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntry();
  }, [entryId]);

  if (loading) return <div>Loading...</div>;
  if (!entry) return <div>Entry not found</div>;

  const basicInfo = {
    'UUID': entry.uuid,
    'Type': entry.type,
    'Owner': entry.owner,
    'Entry Kind': entry.entry_kind,
    'Block Hash': entry.metadata.block_hash,
    'Block Height': entry.metadata.block_index,
    'Created At': new Date(entry.metadata.created_at).toLocaleString(),
    'Updated At': new Date(entry.metadata.updated_at).toLocaleString(),
  };

  const hasParentLinks = blockId || entry.metadata.block_hash;

  return (
    <div className="container mx-auto p-4 space-y-6">
      <h1 className="text-xl md:text-2xl font-bold mb-4">Entry Details</h1>
      
      {hasParentLinks && (
        <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
          <div className="px-4 md:px-6 py-4">
            <div className="flex flex-wrap gap-4">
              {blockId && (
                <Link 
                  to={`/blocks/${blockId}`}
                  className="flex items-center text-blue-600 hover:text-blue-700 text-sm md:text-base"
                >
                  <svg className="w-4 h-4 md:w-5 md:h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                  <span className="break-all">View in Block #{entry.metadata.block_index}</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-4 md:p-6 border-b border-gray-200">
          <h2 className="text-base md:text-lg font-semibold mb-4">Basic Information</h2>
          <div className="overflow-x-auto">
            <JsonTable data={basicInfo} />
          </div>
        </div>

        <div className="p-4 md:p-6">
          <h2 className="text-base md:text-lg font-semibold mb-4">Entry Data</h2>
          <div className="overflow-x-auto">
            <JsonTable 
              data={entry.entry_data} 
              showDownload={true}
              metadata={{
                entryId: entry.uuid,
                blockId: blockId || undefined,
                blockHeight: entry.metadata.block_index
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntryDetails;
