import React from 'react';
import CopyButton from './CopyButton';

interface DetailTableProps {
  data: Record<string, any>;
  title?: string;
}

function DetailTable({ data, title }: DetailTableProps) {
  const shouldShowCopyButton = (key: string, value: any) => {
    const copyFields = ['id', 'hash', 'uuid', 'signature'];
    return copyFields.some(field => key.toLowerCase().includes(field)) && typeof value === 'string';
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      {title && (
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold">{title}</h2>
        </div>
      )}
      <div className="divide-y divide-gray-200">
        {Object.entries(data).map(([key, value], index) => (
          <div
            key={key}
            className={`grid grid-cols-3 gap-4 px-6 py-4 ${
              index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
            }`}
          >
            <div className="font-medium text-gray-700">{key}</div>
            <div className="col-span-2 font-mono text-sm break-all flex items-center">
              {value}
              {shouldShowCopyButton(key, value) && <CopyButton text={value.toString()} />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DetailTable;
