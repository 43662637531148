const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL || 'http://localhost:3005';
  }
  return process.env.REACT_APP_API_URL || '';
};

export const API_CONFIG = {
  BASE_URL: getBaseUrl(),
  ENDPOINTS: {
    BLOCKS: '/blockchain_store/v1/blocks',
    TRANSACTIONS: '/blockchain_store/v1/transactions',
    ENTRIES: '/blockchain_store/v1/entries',
  },
  DEFAULT_PAGE_SIZE: 10,
};
