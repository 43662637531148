import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Block } from '../types/api';
import { api } from '../services/api';
import { getBlockState, getStateColor } from '../utils/blockUtils';
import DetailTable from './common/DetailTable';
import JsonTable from './common/JsonTable';
import OperationDetails from './common/OperationDetails';

function BlockDetails() {
  const { blockId } = useParams();
  const [block, setBlock] = useState<Block | null>(null);
  const [loading, setLoading] = useState(true);
  const [isTransactionsExpanded, setIsTransactionsExpanded] = useState(false);
  const [expandedTransactions, setExpandedTransactions] = useState<Set<number>>(new Set());

  useEffect(() => {
    const fetchBlock = async () => {
      try {
        if (!blockId) return;
        const response = await api.getBlock(blockId);
        setBlock(response.data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlock();
  }, [blockId]);

  if (loading) return <div>Loading...</div>;
  if (!block) return <div>Block not found</div>;

  const state = getBlockState(block);
  const stateColorClass = getStateColor(state);

  const blockData = {
    'Height': block.height,
    'State': <span className={`px-3 py-1 rounded-full text-sm font-medium ${stateColorClass}`}>
      {state.charAt(0).toUpperCase() + state.slice(1)}
    </span>,
    'Hash': block.block_hash,
    'Previous Hash': block.prev_hash,
    'Type': block.type,
    'Created': new Date(block.created_at).toLocaleString(),
  };

  const toggleTransaction = (txId: number) => {
    const newExpanded = new Set(expandedTransactions);
    if (newExpanded.has(txId)) {
      newExpanded.delete(txId);
    } else {
      newExpanded.add(txId);
    }
    setExpandedTransactions(newExpanded);
  };

  const getOperationIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'create':
        return (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        );
      case 'update':
        return (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
        );
      default:
        return (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </svg>
        );
    }
  };

  return (
    <div className="container mx-auto p-4 space-y-6">
      <h1 className="text-2xl font-bold mb-4">Block Details</h1>
      <DetailTable data={blockData} />
      
      {block.transactions && block.transactions.length > 0 && (
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
            <h2 className="text-lg font-semibold">
              Transactions ({block.transactions.length})
            </h2>
            <button
              onClick={() => setIsTransactionsExpanded(!isTransactionsExpanded)}
              className="flex items-center text-gray-500 hover:text-gray-700"
            >
              {isTransactionsExpanded ? 'Collapse' : 'Expand'}
              <svg
                className={`ml-2 h-5 w-5 transform transition-transform ${
                  isTransactionsExpanded ? 'rotate-180' : ''
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>
          </div>
          
          <div className={`transition-all duration-300 ${
            isTransactionsExpanded 
              ? 'max-h-full opacity-100' 
              : 'max-h-0 opacity-0'
          } overflow-hidden`}>
            {block.transactions.map((tx, index) => (
              <div 
                key={tx.hash}
                className={`border-b border-gray-200 last:border-b-0 ${
                  index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                }`}
              >
                <div 
                  className="p-4 hover:bg-gray-100 cursor-pointer"
                  onClick={() => toggleTransaction(tx.id)}
                >
                  <div className="flex justify-between items-start">
                    <div className="flex-1 min-w-0">
                      <Link 
                        to={`/transactions/${tx.id}`}
                        className="font-medium hover:text-blue-600 block"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="flex items-baseline">
                          <span className="text-gray-600 mr-2">Hash:</span>
                          <span className="break-all">{tx.hash}</span>
                        </div>
                      </Link>
                      <p className="text-sm text-gray-600 mt-1">Owner: {tx.owner}</p>
                      <p className="text-sm text-gray-600">
                        Operations: {tx.operations?.length || 0}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 ml-4 flex-shrink-0">
                      <span className="text-sm text-gray-500 whitespace-nowrap">
                        {new Date(tx.date).toLocaleString()}
                      </span>
                      <svg
                        className={`h-5 w-5 transform transition-transform ${
                          expandedTransactions.has(tx.id) ? 'rotate-180' : ''
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </div>
                  </div>
                </div>
                
                {/* Operations Section */}
                <div className={`transition-all duration-300 ${
                  expandedTransactions.has(tx.id)
                    ? 'max-h-full opacity-100'
                    : 'max-h-0 opacity-0'
                } overflow-hidden bg-gray-50`}>
                  <div className="px-6 py-4 space-y-3">
                  {tx.operations?.map((op, index) => (
  <OperationDetails 
    key={`${index}-${op.type}`}
    operation={op}
    index={index}
  />
))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default BlockDetails;
